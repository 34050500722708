<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg show-footer-only-on-last-screen company-documents-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="about-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("documents", "menu", "documents") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
          <router-link
            :to="{
              name: 'r_company-documents-add-document',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>

      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="submitSearch"
        @removeSelectedFilter="removeSelectedFilter"
        :hasFilters="true"
        :selectedFilters="selectedFilters"
        :routeName="'r_company-documents-search-filters'"
      ></search>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <section>
      <div class="scroll"></div>
      <ul
        class="clebex-item-section pill"
        v-if="companyDocuments && companyDocuments.length"
      >
        <li
          class="clebex-item-section-item"
          v-for="(document, index) in companyDocuments"
          :key="index"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="editDocument(document)"
            v-if="!selectMode"
          >
            <span class="label">
              <span class="highlight">{{ document.name }}</span>
              <span v-if="document.application && document.application.name">
                {{ `, ${document.application.name}` }}
              </span>
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-information"></icon>
              </span>
            </span>
          </button>
          <div class="clebex-item-content-wrapper" v-else>
            <div class="checkbox tiny alt block">
              <input
                type="checkbox"
                :id="`document${document.id}`"
                name="document"
                :selected="selectedDocuments.includes(document.id)"
                :value="document.id"
                @change="selectDocument(document)"
              />
              <label :for="`document${document.id}`">
                <svg-icon icon="checkmark-strait"></svg-icon>
                <span class="highlight">
                  {{ document.name }}
                </span>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: selectMode }"
              @click="setSelectMode"
            >
              {{ displayLabelName("documents", "documents", "select") }}
            </button>
          </li>
          <li class="action" :style="deleteButtonStyle">
            <button class="action-btn" @click="showDialog">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedDocuments.length +
                "/" +
                companyDocuments.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>

    <screen-modal
      v-if="showDeleteDialog"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteDialog"
      @close="showDeleteDialog = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("documents", "documents", "delete-title") }}
      </h3>
      <br />
      <p class="text">
        {{ displayLabelName("documents", "documents", "delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
// import EditCompanyDocument from "./EditCompanyDocument.vue";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "CompanyDocuments",
  mixins: [paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      search: null,
      selectMode: false,
      selectedItem: null,
      showDeleteDialog: false,
      selectedDocuments: []
    };
  },
  created() {
    this.submitSearch();
  },
  computed: {
    ...mapState("companyDocuments", ["companyDocuments"]),
    ...mapState("searchFiltersCompanyDocuments", [
      "selectedApplications",
      "searchActiveNum"
    ]),
    deleteButtonStyle() {
      const visibility =
        this.selectMode && this.selectedDocuments.length ? "visible" : "hidden";

      return {
        visibility
      };
    },
    searchQuery: {
      get() {
        return this.$store.state.search.searchQuery;
      },
      set(value) {
        this.$store.commit("search/setSearchQuery", value, {
          root: true
        });
      }
    }
  },
  watch: {
    searchActiveNum() {
      this.submitSearch();
    }
  },
  methods: {
    ...mapActions("companyDocuments", [
      "getCompanyDocuments",
      "deleteCompanyDocument"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    editDocument(document) {
      setTimeout(() => {
        this.$router.push({
          name: "r_company-documents-edit-document",
          params: { documentId: document.id }
        });
      }, 0);
    },
    selectDocument(document) {
      if (this.selectedDocuments.includes(document.id)) {
        this.selectedDocuments.splice(
          this.selectedDocuments.indexOf(document.id),
          1
        );
      } else {
        this.selectedDocuments.push(document.id);
      }
    },
    toggleSearch() {
      this.search = !this.search;
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    handleSelection(id) {
      this.selectedItem = id;
    },
    clearSearchQuery() {
      this.searchQuery = "";
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.submitSearch();
    },
    submitSearch() {
      this.getCompanyDocuments({
        search: this.searchQuery,
        application_ids: this.selectedApplications
      });
    },
    removeSelectedFilter() {},
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedDocuments.length; i++) {
        await this.deleteCompanyDocument(this.selectedDocuments[i]);
      }

      this.setPaginationData(null);
      this.selectMode = false;
      this.selectedDocuments = [];
      this.getCompanyDocuments();
    },
    setSelectMode() {
      this.selectedItem = null;
      this.selectMode = !this.selectMode;
    },
    showDialog() {
      this.showDeleteDialog = true;
    },
    closeExportDialog() {
      this.showDeleteDialog = false;
    }
  },
  beforeUnmount() {
    this.$store.commit("search/setSearchQuery", "", {
      root: true
    });
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
